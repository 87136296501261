(function (angular, _) {
    var MyHippoProducerServices = angular.module('MyHippoProducer.Services');
    MyHippoProducerServices.factory('UserService', function ($log, HeapService) {
        let currentUser;
        const userMethods = {
            init: (userData) => {
                currentUser = userData;
                $log.info('Initialized user data', userData);
                HeapService.addUserProperties({
                    email: userData.email,
                    organization_id: userData.organization_id,
                    organization_name: userData.main_organization.name,
                    is_producer: true,
                });
            },
            getCurrentUser: () => currentUser,
            isPolicyCreationDisabled: () => _.get(currentUser, 'organization_policy_creation_disabled'),
            // Roles
            getRoles: () => _.get(currentUser, 'data.roles', {}),
            isAdmin: () => _.get(currentUser, 'data.roles.admin', false),
            isPrincipal: () => _.get(currentUser, 'data.roles.principal', false),
            isMasterAgent: () => _.get(currentUser, 'data.roles.master_agent', false),
            isCustomerService: () => _.get(currentUser, 'data.roles.customer_service', false),
            // User Data
            getName: () => _.get(currentUser, 'data.name', 'N/A'),
            getId: () => _.get(currentUser, 'id'),
            getOrgId: () => _.get(currentUser, 'organization_id'),
            getRootOrgId: () => _.get(currentUser, 'main_organization_id'),
            getParentOrgId: () => _.get(currentUser, 'parent_organization_id'),
            getEmail: () => _.get(currentUser, 'data.email'),
            showNewPolicyDesign: () => _.get(currentUser, 'data.show_new_policy_page', false),
            getUserSubOrganizations: () => _.get(currentUser, 'data.sub_organizations', []),
            getPartnerStyling: () => {
                // TODO: Hack to show toll brother logo
                const { subdomain } = _.get(currentUser, 'main_organization', {});
                if (subdomain === 'tollbrothers') {
                    return {
                        namme: 'tollbrothers',
                        logoUrl: 'img/tollbrothers.png'
                    };
                }
                return null;
            },
            getLastLogin: () => {
                const userLastLogin = _.get(currentUser, 'data.last_login', undefined);
                return Date.parse(userLastLogin);
            },
            isBuilder: () => _.get(currentUser, 'is_builder'),
        };

        // Permissions logic
        _.merge(userMethods, {
            canViewCreatedByFilter: () => userMethods.isAdmin() || userMethods.isPrincipal() || userMethods.isMasterAgent(),
            canEditProducers: () => userMethods.isAdmin() || userMethods.isPrincipal(),
            canEditOrganizations: () => userMethods.isPrincipal(),
            canViewOrgFromProfileDropdown: () => userMethods.isAdmin(),
            canViewAgentName: () => userMethods.isAdmin() || userMethods.isPrincipal() || userMethods.isMasterAgent(),
            canViewReports: () => _.get(window, 'PRODUCER_ENABLE_REPORTS', false) && userMethods.isPrincipal(),
            canViewDocuments: () => userMethods.isAdmin() || userMethods.isPrincipal(),
            canViewAdminPanel: () => userMethods.isAdmin() || userMethods.isPrincipal(),
        });

        return _.merge(userMethods, {
            getUser: () => ({
                disabledPolicyCreation: userMethods.isPolicyCreationDisabled(),
                isAdmin: userMethods.isAdmin(),
                isPrincipal: userMethods.isPrincipal(),
                name: userMethods.getName(),
                email: userMethods.getEmail(),
                id: userMethods.getId(),
                orgId: userMethods.getOrgId(),
                getLastLogin: userMethods.getLastLogin(),
            }),
        });
    });
})(window.angular, window._);
